import "./App.css";
import { Routes, Route } from "react-router-dom";
// import Index from "./Components/Home/Index";
// import Migrate from "./Pages/Pool/V3/Migrate/Migrate";
import Index from "./Pages/Home/Index";
import Ecosystem from "./Pages/Ecosystem/Ecosystem";
import Community from "./Pages/Community/Community";
import GovernanceNew from "./Pages/Governance/GovernanceNew";
import DevelopersNew from "./Pages/Developers/DevelopersNew";
import PrivacyPolicy from "./Pages/Privacy-Policy/PrivacyPolicy";
import TermsConditions from "./Pages/Terms-Conditions/TermsConditions";
function App() {
  return (
    <Routes>
      <Route path="/" element={<Index />} />
      <Route path="/ecosystem" element={<Ecosystem />} />
      <Route path="/community" element={<Community />} />
      <Route path="/governance" element={<GovernanceNew />} />
      <Route path="/developers" element={<DevelopersNew />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-conditions" element={<TermsConditions />} />
    </Routes>
  );
}

export default App;
