/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

export default function Hero() {
  return (
    <div>
      <div className="banner-wrapper style13  py-4 ">
        <div className="container-wide py-xl-5">
          <div className="row justify-content-between">
            <div className="col-lg-5 col-md-12 mt-md-4 md-mb-3 ">
              <h1 className="display5-size lh-1 text-gray-900 dark-text rajdhani-700">
                Find Best Swapping Rates
              </h1>
              <p className="rajdhani-500 dark-text h6 lh-28 text-gray-700 w-xl-75 mt-2">
                Value fetches best swapping rates from all liquidity pools &
                brings you most beneficial trades.{" "}
              </p>
              <div className="d-flex flex-row flex-wrap gap-3 mt-4 pt-3">
                <a
                  href="https://app.value.xeer.store"
                  target="_blank"
                  className="btn btn-lg text-white rajdhani-600 rounded-6 bg-gray-900 border-dark border-2 px-lg-5 font-sm "
                >
                  Enter dApp
                </a>
                <a
                  href="#"
                  className="btn btn-lg text-white  rajdhani-700 rounded-6 bg-current border-current border-2 px-lg-5 font-sm "
                >
                  Documentation
                </a>
              </div>
            </div>
            <div className="col-lg-7 col-md-12 md-mt-4">
              <img
                src="assets/newimg/imagefind_best_swapping_rates-1.png"
                className="img-fluid w-100"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
