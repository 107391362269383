import React from 'react'
import TermsConditionsHero from './TermsConditionsHero'
import Header from '../../Components/Coman/Header'
import Footer2 from '../../Components/Coman/Footer2'

export default function TermsConditions() {
  return (
    <div>
         <div className="main-wrapper bg-dark-black">
                <Header/>
                <TermsConditionsHero/>
                <Footer2/>
              </div>

    </div>
  )
}
