import React from "react";

export default function CommunityJoin() {
  return (
    <div>
      <div className="feature-wrapper ">
        <div className="container-wide  pb-5  ">
          <div className="row  align-items-center">
            <div className=" col-12 ">
              <h2 className="text-gray-900 dark-text lh-3  rajdhani-600 h2 mb-0">
                Join Us!
              </h2>
              <h2 class="text-gray-900 dark-text  rajdhani-500 h6  mt-3">
                Connect to our community and join discussion around potential
                integrations, trading <br /> strategies & upcoming trends in the
                DeFi ecosystem.{" "}
              </h2>
            </div>
            <div className="col-md-6 col-12 ps-1 mt-2">
              <div className="rounded-15 p-3 hero-bg-new border dark-border">
                <a href="#">
                  <h2 className="post-title lh-16 mt-1 mb-1 d-flex justify-content-between">
                    <span className="rajdhani-600 text-gray-900 text-white h5 ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        fill="currentColor"
                        class="bi bi-twitter-x me-2"
                        viewBox="0 0 16 16"
                      >
                        <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
                      </svg>{" "}
                      Twitter{" "}
                    </span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32px"
                      height="32px"
                      className="dark-text"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <line x1={7} y1={17} x2={17} y2={7} />
                      <polyline points="7 7 17 7 17 17" />
                    </svg>
                  </h2>
                  <p className="lh-24 font-sm text-gray-900 rajdhani-50">
                    <span className="lh-24 font-sm text-white rajdhani-500 mt-1 ">
                      Follow Value on Twitter, best for announcements!
                    </span>
                  </p>
                </a>
              </div>
            </div>
            <div className="col-md-6 col-12 ps-1 mt-2">
              <div className="rounded-15 p-3 hero-bg-new border dark-border">
                <a href="#">
                  <h2 className="post-title lh-16 mt-1 mb-1 d-flex justify-content-between align-items-center">
                    <span className="rajdhani-600 text-gray-900 text-white h5 d-flex align-items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        fill="currentColor "
                        class="bi bi-discord me-2"
                        viewBox="0 0 16 16"
                      >
                        <path d="M13.545 2.907a13.2 13.2 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.2 12.2 0 0 0-3.658 0 8 8 0 0 0-.412-.833.05.05 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.04.04 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032q.003.022.021.037a13.3 13.3 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019q.463-.63.818-1.329a.05.05 0 0 0-.01-.059l-.018-.011a9 9 0 0 1-1.248-.595.05.05 0 0 1-.02-.066l.015-.019q.127-.095.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.05.05 0 0 1 .053.007q.121.1.248.195a.05.05 0 0 1-.004.085 8 8 0 0 1-1.249.594.05.05 0 0 0-.03.03.05.05 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.2 13.2 0 0 0 4.001-2.02.05.05 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.03.03 0 0 0-.02-.019m-8.198 7.307c-.789 0-1.438-.724-1.438-1.612s.637-1.613 1.438-1.613c.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612m5.316 0c-.788 0-1.438-.724-1.438-1.612s.637-1.613 1.438-1.613c.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612" />
                      </svg>
                      Discord{" "}
                    </span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32px"
                      height="32px"
                      className="dark-text"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <line x1={7} y1={17} x2={17} y2={7} />
                      <polyline points="7 7 17 7 17 17" />
                    </svg>
                  </h2>
                  <p className="lh-24 font-sm text-gray-900 rajdhani-50">
                    <span className="lh-24 font-sm text-white rajdhani-500 mt-1 ">
                      Join our Discord server to catch up with inside
                      information.
                    </span>
                  </p>
                </a>
              </div>
            </div>
            <div className="col-md-6 col-12 ps-1 mt-2">
              <div className="rounded-15 p-3 hero-bg-new border dark-border">
                <a href="#">
                  <h2 className="post-title lh-16 mt-1 mb-1 d-flex justify-content-between align-items-center">
                    <span className="rajdhani-600 text-gray-900 text-white h5 d-flex align-items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" fill="currentColor" class="bi bi-telegram me-2" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.287 5.906q-1.168.486-4.666 2.01-.567.225-.595.442c-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294q.39.01.868-.32 3.269-2.206 3.374-2.23c.05-.012.12-.026.166.016s.042.12.037.141c-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8 8 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629q.14.092.27.187c.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.4 1.4 0 0 0-.013-.315.34.34 0 0 0-.114-.217.53.53 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09"/>
</svg>
                      Telegram
                    </span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32px"
                      height="32px"
                      className="dark-text"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <line x1={7} y1={17} x2={17} y2={7} />
                      <polyline points="7 7 17 7 17 17" />
                    </svg>
                  </h2>
                  <p className="lh-24 font-sm text-gray-900 rajdhani-50">
                    <span className="lh-24 font-sm text-white rajdhani-500 mt-1 ">
                    Chat with thousands of other fellow community members.

                    </span>
                  </p>
                </a>
              </div>
            </div>
            <div className="col-md-6 col-12 ps-1 mt-2">
              <div className="rounded-15 p-3 hero-bg-new border dark-border">
                <a href="#">
                  <h2 className="post-title lh-16 mt-1 mb-1 d-flex justify-content-between align-items-center">
                    <span className="rajdhani-600 text-gray-900 text-white h5 d-flex align-items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" fill="currentColor" class="bi bi-youtube me-2" viewBox="0 0 16 16">
  <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z"/>
</svg>
                      YouTube

                    </span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32px"
                      height="32px"
                      className="dark-text"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <line x1={7} y1={17} x2={17} y2={7} />
                      <polyline points="7 7 17 7 17 17" />
                    </svg>
                  </h2>
                  <p className="lh-24 font-sm text-gray-900 rajdhani-50">
                    <span className="lh-24 font-sm text-white rajdhani-500 mt-1 ">
                    Learn “How To” & all about Vale on YouTube. 
                    </span>
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
