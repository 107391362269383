import React from "react";
import Header from "../../Components/Coman/Header";
import Hero from "./Hero";
import Rodemap from "./Rodemap";
import Howitworks from "./Howitworks";
import Pathfinder from "./Pathfinder";
import Protocols from "./Protocols";
import DApp from "./DApp";
// import Footer from "../../Coman/Footer";
import Community from "./CommunityHero";
import Rodemapcopy from "./Rodemapcopy";
import Footer2 from "../../Components/Coman/Footer2";
import Blog from "./Blog";
import Ecosystem from "./Ecosystem";
import Developers from "./Developers";
import NewHero from "./NewHero";
import CommunityHero from "./CommunityHero";
// import SwapHeader from "../../Components/Coman/SwapHeader";

export default function Index() {
  return (
    <div>
      <div className="main-wrapper bg-dark-black">
        <Header />
        <NewHero />
        <Hero />
        {/* <Rodemapcopy /> */}
        <Ecosystem />
        <Developers />
        <Howitworks />
        <Pathfinder />
        <Protocols />
        <DApp />
        {/* <Blog/> */}
        <CommunityHero />
        <Footer2 />
      </div>
    </div>
  );
}
