import React from "react";

export default function Pathfinder() {
  return (
    <div>
      <div className="feature-wrapper ">
        <div className="container-wide  py-lg-5 ">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 md-mt-4">
              <h2 className="text-gray-900 dark-text lh-3 mb-4 rajdhani-600 h1 pe-lg-5">
                Multichain Support
              </h2>
              <h6 className="text-gray-700 dark-text-1 rajdhani-500 lh-30 ">
                Transfer assets between all major blockchain seamlessly. Value
                expands the current interchain transaction capabilities with
                support for leading blockchain ecosystems
              </h6>
            </div>
            <div className="col-lg-5 col-md-12  ms-auto">
              <img
                src="assets/newimg/multichain_support.png"
                alt="banner"
                className="img-fluid h-100 w-100"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
