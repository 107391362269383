/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export default function Protocols() {
  var settings = {
    arrows: false,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <div className="seller-wrapper">
        <div className="container-wide  py-5 ">
          <div className="row">
            <div className="col-12 d-flex pb-lg-5 pb-4">
              <h2 className="text-gray-900 audiowide-400 h2 mb-0 dark-text" id="Paris">
                Integrated protocols
              </h2>
           
            </div>
          </div>
          <Slider
            {...settings}
          >
            <div key={1}>
              <div className="item mx-2">
                <div className="d-flex flex-column ovh rounded-15 border dark-border ">
                  <div className="d-flex flex-column posr text-center">
                    <figure className="mb-0  rounded-15">
                      <img
                        src="assets/newimg/uniswap.png"
                        alt="user"
                        className=" w-100 w-100 light-icon-done"
                      />
                       <img
                        src="assets/newimg/uniswapdark.png"
                        alt="user"
                        className=" w-100 w-100 dark-icon-done "
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
            <div key={2}>
              <div className="item mx-2">
                <div className="d-flex flex-column ovh rounded-15 border dark-border ">
                  <div className="d-flex flex-column posr text-center">
                    <figure className="mb-0  rounded-15">
                      <img
                        src="assets/newimg/baseswap.png"
                        alt="user"
                        className=" w-100 w-100 light-icon-done"
                      />
                        <img
                        src="assets/newimg/baseswap-dark.png"
                        alt="user"
                        className=" w-100 w-100 dark-icon-done"
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
            <div key={3}>
              <div className="item mx-2">
                <div className="d-flex flex-column ovh rounded-15 border dark-border ">
                  <div className="d-flex flex-column posr text-center">
                    <figure className="mb-0  rounded-15">
                      <img
                        src="assets/newimg/cake.png"
                        alt="user"
                        className=" w-100 w-100 light-icon-done"
                      />
                       <img
                        src="assets/newimg/cake-dark.png"
                        alt="user"
                        className=" w-100 w-100 dark-icon-done"
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
            <div key={4}>
              <div className="item mx-2">
                <div className="d-flex flex-column ovh rounded-15 border dark-border ">
                  <div className="d-flex flex-column posr text-center">
                    <figure className="mb-0  rounded-15">
                      <img
                        src="assets/newimg/jupiter.png"
                        alt="user"
                        className=" w-100 w-100 light-icon-done"
                      />
                        <img
                        src="assets/newimg/jupiter-dark.png"
                        alt="user"
                        className=" w-100 w-100 dark-icon-done"
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
            <div key={5}>
              <div className="item mx-2">
                <div className="d-flex flex-column ovh rounded-15 border dark-border ">
                  <div className="d-flex flex-column posr text-center">
                    <figure className="mb-0  rounded-15">
                      <img
                        src="assets/newimg/uniswap.png"
                        alt="user"
                        className=" w-100 w-100 light-icon-done"
                      />
                       <img
                        src="assets/newimg/uniswapdark.png"
                        alt="user"
                        className=" w-100 w-100 dark-icon-done "
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
            <div key={6}>
              <div className="item mx-2">
                <div className="d-flex flex-column ovh rounded-15 border dark-border ">
                  <div className="d-flex flex-column posr text-center">
                    <figure className="mb-0  rounded-15">
                      <img
                        src="assets/newimg/baseswap.png"
                        alt="user"
                        className=" w-100 w-100 light-icon-done"
                      />
                        <img
                        src="assets/newimg/baseswap-dark.png"
                        alt="user"
                        className=" w-100 w-100 dark-icon-done"
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
            <div key={7}>
              <div className="item mx-2">
                <div className="d-flex flex-column ovh rounded-15 border dark-border ">
                  <div className="d-flex flex-column posr text-center">
                    <figure className="mb-0  rounded-15">
                      <img
                        src="assets/newimg/cake.png"
                        alt="user"
                        className=" w-100 w-100 light-icon-done"
                      />
                       <img
                        src="assets/newimg/cake-dark.png"
                        alt="user"
                        className=" w-100 w-100 dark-icon-done"
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
            <div key={8}>
              <div className="item mx-2">
                <div className="d-flex flex-column ovh rounded-15 border dark-border ">
                  <div className="d-flex flex-column posr text-center">
                    <figure className="mb-0  rounded-15">
                      <img
                        src="assets/newimg/jupiter.png"
                        alt="user"
                        className=" w-100 w-100 light-icon-done"
                      />
                        <img
                        src="assets/newimg/jupiter-dark.png"
                        alt="user"
                        className=" w-100 w-100 dark-icon-done"
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
          {/* <div className="mt-5 d-flex">
            <a className="button" onClick={previous}>
              <i className="bi bi-arrow-left text-white" />
            </a>
            <a className="button" onClick={next}>
              <i className="bi bi-arrow-right" />
            </a>
          </div> */}

          {/* <div className="row">
            <div className="col-lg-1 col-md-1 col-sm-2 col-4 my-2">
              <a
                href="#"
                className="btn btn-xs text-gray-900 rajdhani-600 rounded-pill bg-box-tab font-sm text-uppercase add-to-save"
              
              >
                <img src="assets/images/1inch.svg" alt="user"  />
              </a>
            </div>
            <div className="col-lg-1 col-md-1 col-sm-2 col-4 my-2">
              <a
                href="#"
                className="btn btn-xs text-gray-900 rajdhani-600 rounded-pill bg-box-tab font-sm text-uppercase add-to-save"
              
              >
                <img src="assets/images/1inch.svg" alt="user" />
              </a>
            </div>
            <div className="col-lg-1 col-md-1 col-sm-2 col-4 my-2">
              <a
                href="#"
                className="btn btn-xs text-gray-900 rajdhani-600 rounded-pill bg-box-tab font-sm text-uppercase add-to-save"
              
              >
                <img src="assets/images/1inch.svg" alt="user" />
              </a>
            </div>
            <div className="col-lg-1 col-md-1 col-sm-2 col-4 my-2">
              <a
                href="#"
                className="btn btn-xs text-gray-900 rajdhani-600 rounded-pill bg-box-tab font-sm text-uppercase add-to-save"
              
              >
                <img src="assets/images/1inch.svg" alt="user" />
              </a>
            </div>
            <div className="col-lg-1 col-md-1 col-sm-2 col-4 my-2">
              <a
                href="#"
                className="btn btn-xs text-gray-900 rajdhani-600 rounded-pill bg-box-tab font-sm text-uppercase add-to-save"
              
              >
                <img src="assets/images/1inch.svg" alt="user" />
              </a>
            </div>
            <div className="col-lg-1 col-md-1 col-sm-2 col-4 my-2">
              <a
                href="#"
                className="btn btn-xs text-gray-900 rajdhani-600 rounded-pill bg-box-tab font-sm text-uppercase add-to-save"
              
              >
                <img src="assets/images/1inch.svg" alt="user" />
              </a>
            </div>
            <div className="col-lg-1 col-md-1 col-sm-2 col-4 my-2">
              <a
                href="#"
                className="btn btn-xs text-gray-900 rajdhani-600 rounded-pill bg-box-tab font-sm text-uppercase add-to-save"
              
              >
                <img src="assets/images/1inch.svg" alt="user" />
              </a>
            </div>
            <div className="col-lg-1 col-md-1 col-sm-2 col-4 my-2">
              <a
                href="#"
                className="btn btn-xs text-gray-900 rajdhani-600 rounded-pill bg-box-tab font-sm text-uppercase add-to-save"
              
              >
                <img src="assets/images/1inch.svg" alt="user" />
              </a>
            </div>
            <div className="col-lg-1 col-md-1 col-sm-2 col-4 my-2">
              <a
                href="#"
                className="btn btn-xs text-gray-900 rajdhani-600 rounded-pill bg-box-tab font-sm text-uppercase add-to-save"
              
              >
                <img src="assets/images/1inch.svg" alt="user" />
              </a>
            </div>
            <div className="col-lg-1 col-md-1 col-sm-2 col-4 my-2">
              <a
                href="#"
                className="btn btn-xs text-gray-900 rajdhani-600 rounded-pill bg-box-tab font-sm text-uppercase add-to-save"
              
              >
                <img src="assets/images/1inch.svg" alt="user" />
              </a>
            </div>
            <div className="col-lg-1 col-md-1 col-sm-2 col-4 my-2">
              <a
                href="#"
                className="btn btn-xs text-gray-900 rajdhani-600 rounded-pill bg-box-tab font-sm text-uppercase add-to-save"
              
              >
                <img src="assets/images/1inch.svg" alt="user" />
              </a>
            </div>
            <div className="col-lg-1 col-md-1 col-sm-2 col-4 my-2">
              <a
                href="#"
                className="btn btn-xs text-gray-900 rajdhani-600 rounded-pill bg-box-tab font-sm text-uppercase add-to-save"
              
              >
                <img src="assets/images/1inch.svg" alt="user" />
              </a>
            </div>
            <div className="col-lg-1 col-md-1 col-sm-2 col-4 my-2">
              <a
                href="#"
                className="btn btn-xs text-gray-900 rajdhani-600 rounded-pill bg-box-tab font-sm text-uppercase add-to-save"
              
              >
                <img src="assets/images/1inch.svg" alt="user" />
              </a>
            </div>
            <div className="col-lg-1 col-md-1 col-sm-2 col-4 my-2">
              <a
                href="#"
                className="btn btn-xs text-gray-900 rajdhani-600 rounded-pill bg-box-tab font-sm text-uppercase add-to-save"
              
              >
                <img src="assets/images/1inch.svg" alt="user" />
              </a>
            </div>
            <div className="col-lg-1 col-md-1 col-sm-2 col-4 my-2">
              <a
                href="#"
                className="btn btn-xs text-gray-900 rajdhani-600 rounded-pill bg-box-tab font-sm text-uppercase add-to-save"
              
              >
                <img src="assets/images/1inch.svg" alt="user" />
              </a>
            </div>
            <div className="col-lg-1 col-md-1 col-sm-2 col-4 my-2">
              <a
                href="#"
                className="btn btn-xs text-gray-900 rajdhani-600 rounded-pill bg-box-tab font-sm text-uppercase add-to-save"
              
              >
                <img src="assets/images/1inch.svg" alt="user" />
              </a>
            </div>
            <div className="col-lg-1 col-md-1 col-sm-2 col-4 my-2">
              <a
                href="#"
                className="btn btn-xs text-gray-900 rajdhani-600 rounded-pill bg-box-tab font-sm text-uppercase add-to-save"
              
              >
                <img src="assets/images/1inch.svg" alt="user" />
              </a>
            </div>
            <div className="col-lg-1 col-md-1 col-sm-2 col-4 my-2">
              <a
                href="#"
                className="btn btn-xs text-gray-900 rajdhani-600 rounded-pill bg-box-tab font-sm text-uppercase add-to-save"
              
              >
                <img src="assets/images/1inch.svg" alt="user" />
              </a>
            </div>
            <div className="col-lg-1 col-md-1 col-sm-2 col-4 my-2">
              <a
                href="#"
                className="btn btn-xs text-gray-900 rajdhani-600 rounded-pill bg-box-tab font-sm text-uppercase add-to-save"
              
              >
                <img src="assets/images/1inch.svg" alt="user" />
              </a>
            </div>
            <div className="col-lg-1 col-md-1 col-sm-2 col-4 my-2">
              <a
                href="#"
                className="btn btn-xs text-gray-900 rajdhani-600 rounded-pill bg-box-tab font-sm text-uppercase add-to-save"
              
              >
                <img src="assets/images/1inch.svg" alt="user" />
              </a>
            </div>
            <div className="col-lg-1 col-md-1 col-sm-2 col-4 my-2">
              <a
                href="#"
                className="btn btn-xs text-gray-900 rajdhani-600 rounded-pill bg-box-tab font-sm text-uppercase add-to-save"
              
              >
                <img src="assets/images/1inch.svg" alt="user" />
              </a>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
