import React from 'react'
import Header from '../../Components/Coman/Header'
import Footer2 from '../../Components/Coman/Footer2'
import GovernanceHero from './GovernanceHero'
import GovernanceApps from './GovernanceApps'

export default function GovernanceNew() {
  return (
    <div>
                  <div className="main-wrapper bg-dark-black">
            <Header/>
           <GovernanceHero/>
           <GovernanceApps/>
            <Footer2/>
          </div>
    </div>
  )
}
