import React from "react";

export default function Ecosystem() {
  return (
    <div>
      <div className="feature-wrapper ">
        <div className="container-wide  py-5 mt-5">
          <h2 className="text-gray-900 dark-text lh-3 mb-4 rajdhani-600 h1 ">
          Value ecosystem
          </h2>
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-12">
              <div className="d-flex posr mb-4">
                <figure
                  className="mb-0 w-100 rounded-25 h-300 bg-image-cover bg-no-repeat bg-image-center align-items-end d-flex"
                  style={{ backgroundImage: "url(assets/newimg/serving_millions_of_users.png)" }}
                >
                  <div
                    className=" card-body  bg-wallet-2 d-flex align-items-center justify-content-center py-3 "
                    style={{ borderRadius: "0px 0px 23px 23px" }}
                  >
                    <div className="d-flex my-1 align-items-center mb-0 ju">
                      <h2 className=" lh-1 text-gray-900 dark-text m-0 rajdhani-700">
                        100+ &nbsp;{" "}
                      </h2>
                      <div />
                      <p className=" lh-1 text-gray-900 dark-text font-sm-new m-0 me-sm-1 me-md-2 me-1 rajdhani-600">
                        Integrations upcoming
                      </p>
                      <div />
                      <a
                        href="#Paris"
                        type="button"
                        className="btn  py-1 font-sm-new rounded-10 btn-primary"
                      >
                        {" "}
                        See All
                      </a>
                    </div>
                  </div>
                </figure>
              </div>
            </div>

            <div className=" col-lg-6 col-md-12 ms-auto ">
              <h2 className="text-gray-900 dark-text lh-3 mb-4 rajdhani-600 h1 pe-lg-5">
                Serving millions of users
              </h2>
              <h6 className="text-gray-700 dark-text rajdhani-500 lh-30 pe-lg-5 me-lg-5">
                Value has integrated a number of dApps for millions of users
                within the DeFi ecosystem while enable seamless swaps.
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
