import React from "react";

export default function PrivacyPolicyHero() {
  return (
    <div>
      <div className="feature-wrapper ">
        <div className="container-wide  mt-lg-100 mt-0 pt--lg-100 pb-3 ">
          <div className="row align-items-center">
            <div className="12 md-mt-4">
              <h2 className="text-gray-900 dark-text lh-3 mb-4 rajdhani-600 h2 pe-lg-5">
              Privacy Policy
              </h2>
              <p className="rajdhani-500 dark-text font-sm  text-gray-700 ">
              Welcome to Value DEX! This Privacy Policy outlines how we collect, use, and protect your personal information when you engage with our decentralized exchange platform. By utilizing Value DEX, you agree to the terms of this Privacy Policy.              
                 </p>
                 <p className="rajdhani-500 dark-text font-sm  text-gray-700 ">
                 Information We Collect: We may collect personal information such as your name, email address, and payment details when you register an account or engage in transactions on Value DEX. Additionally, we may collect technical information such as your IP address, device information, and browsing activity.
</p>
<p className="rajdhani-500 dark-text font-sm  text-gray-700 ">
Use of Information: We use the collected information to provide and improve our services, process transactions, communicate with you, and ensure the security of our platform. We may also use your information for legal and regulatory compliance purposes.
</p>
<p className="rajdhani-500 dark-text font-sm  text-gray-700 ">
Data Security: We are committed to safeguarding your personal information and maintaining the security of our platform. We implement industry-standard security measures to protect against unauthorized access, alteration, disclosure, or destruction of your personal information.
</p>
<p className="rajdhani-500 dark-text font-sm  text-gray-700 ">
Information Sharing: We do not sell, trade, or otherwise transfer your personal information to third parties without your consent, except as required by law or to fulfill the purposes outlined in this Privacy Policy.
</p>
<p className="rajdhani-500 dark-text font-sm  text-gray-700 ">
Cookies and Analytics: We may use cookies and similar tracking technologies to enhance your experience on Value DEX and gather information about how you interact with our platform.
</p>
<p className="rajdhani-500 dark-text font-sm  text-gray-700 ">
Third-Party Links: Value DEX may contain links to third-party websites or services. We are not responsible for the privacy practices or content of these third parties and encourage you to review their privacy policies.
</p>
<p className="rajdhani-500 dark-text font-sm  text-gray-700 ">
Children’s Privacy: Value DEX is not intended for use by individuals under the age of 18. We do not knowingly collect personal information from individuals in this age group.
</p>
<p className="rajdhani-500 dark-text font-sm  text-gray-700 ">
Changes to Privacy Policy: We reserve the right to update or modify this Privacy Policy at any time. We will notify users of any changes, and your continued use of Value DEX after the changes have been made constitutes acceptance of the updated policy.
</p>
<p className="rajdhani-500 dark-text font-sm  text-gray-700 ">
Contact Us: If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at [contact information].
</p>
<p className="rajdhani-500 dark-text font-sm  text-gray-700 mb-5 ">
By engaging with Value DEX, you acknowledge and consent to the collection and use of your personal information as outlined in this Privacy Policy.
</p>
            </div>
          </div>
       
        </div>
      </div>
    </div>
  );
}
