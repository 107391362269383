import React from "react";

export default function Developers() {
  return (
    <div>
      <div className="feature-wrapper ">
        <div className="container-wide  py-2 ">
          <h2 className="text-gray-900 dark-text lh-3 mb-4 rajdhani-600 h1 ">
            Developers
          </h2>
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-12 mt-2">
              <div className="d-flex posr mb-4" style={{ height: "325px" }}>
                <figure
                  className="mb-0 w-100 rounded-25 bg-image-cover bg-no-repeat bg-image-center align-items-center px-3 px-md-3 px-lg-4 d-flex py-4 border dark-border hero-bg-new"
                  // style={{
                  //   backgroundImage:
                  //     "url(assets/newimg/build_multichain_dapps.png)",
                  // }}
                >
                  <div className="feature-div px-3 px-md-3 px-lg-4 pt-2">
                    <img
                      src="assets/newimg/logo_01_1.webp"
                      alt="box"
                      className="img-fluid mb-4"
                      height="60px"
                      width="60px"
                    />
                    <h2 className="rajdhani-500 h3 mt-2 text-white">
                      Build Multichain dApps
                    </h2>
                    <p className="pe-3 rajdhani-500 font-sm mb-0 text-white">
                      Use Value’s multichain infrastructure to build & deploy
                      interoperable dApps with easy integrations, accessibility,
                      & composability.
                    </p>
                    <button
                      type="button"
                      className="btn  py-1  font-sm rounded-10 btn-primary mt-3"
                    >
                      Build{" "}
                    </button>
                  </div>
                </figure>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 mt-2">
              <div className="d-flex posr mb-4" style={{ height: "325px" }}>
                <figure
                  className="mb-0 w-100 rounded-25 bg-image-cover bg-no-repeat bg-image-center align-items-center px-3 px-md-3 px-lg-4 d-flex py-4 border dark-border hero-bg-new"
                  // style={{
                  //   backgroundImage: "url(assets/newimg/access_grants.png)",
                  // }}
                >
                  <div className="feature-div px-3 px-md-3 px-lg-4 pt-2">
                    <img
                      src="assets/newimg/logo_01_1.webp"
                      alt="box"
                      className="img-fluid mb-4"
                      height="60px"
                      width="60px"
                    />
                    <h2 className="rajdhani-500 h3 mt-2 text-white">
                      Access Grants
                    </h2>
                    <p className="pe-3 rajdhani-500 font-sm mb-0 text-white">
                      Get funded from our DAO to develop decentralized
                      applications for the Value ecosystem.
                    </p>
                    <button
                      type="button"
                      className="btn  py-1  font-sm rounded-10 btn-primary mt-3"
                    >
                      Grants{" "}
                    </button>
                  </div>
                </figure>
              </div>
            </div>

            {/* <div className="col-lg-5 mt-2">
              <div className="d-flex posr">
                <figure
                  className="mb-0 w-100 rounded-25 bg-image-cover bg-no-repeat bg-image-center"
                  style={{
                    backgroundImage: "url(assets/images/nft-bg-1.jpg)",
                    height: "450px",
                  }}
                >
                    <div className="feature-div px-3 px-md-3 px-lg-4 pt-4">
  <img src="assets/newimg/logo_01_1.webp" alt="box" className="img-fluid w-110 mb-4" />
  <h2 className="rajdhani-500 h3 mt-2">Access Grants
  </h2>
  <p className="pe-3 rajdhani-500 font-sm mb-0">Get funded from our DAO to develop decentralized applications for the Value ecosystem. 
  </p>
  <button type="button" className="btn  py-1  font-sm rounded-10 btn-primary mt-3">Grants </button>

</div>

                </figure>
              
            
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
