import React from 'react'

export default function CommunityHero() {
  return (
    <div>
         <div className="feature-wrapper ">
        <div className="container-wide  mt-lg-100 mt-0 pt--lg-100  ">
          <div className="row align-items-center">
            <div className="12 md-mt-4">
              <h2 className="text-gray-900 dark-text lh-3 mb-2 rajdhani-500 h4  pe-lg-5 ">
              Value Community
              </h2>
              <h6 className=" dark-text rajdhani-600 h2 ">
              Value has a decentralized community of developers, <br /> traders, educators & crypto evangelists. 

              </h6>
            <hr style={{border:"0.4px solid #666666"}} className='my-5' />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
