import React from "react";

export default function DevelopersHero() {
  return (
    <div>
      <div className="feature-wrapper ">
        <div className="container-wide  mt-lg-100 mt-0 pt--lg-100 pb-3 ">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 md-mt-4">
              <h2 className="text-gray-900 dark-text lh-3 mb-4 rajdhani-600 h2 pe-lg-5">
              Developers 
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <img
                src="assets/newimg/developers-hero.jpg"
                className="img-fluid w-100 rounded-15"
              />
            </div>
          </div>
          <div className="row my-4">
            <div className="col-lg-4 ps-1 mt-2">
              <div
                className="rounded-15 p-3 hero-bg-new border dark-border d-flex align-items-center"
                style={{ height: "234px" }}
              >
                <a href="#">
                  <img
                    src="assets/newimg/uniswap.webp"
                    className="img-fluid h-45"
                  />
                  <h2 className="post-title lh-16 mt-3 mb-1 d-flex justify-content-between">
                    <span className="rajdhani-600 text-gray-900 text-white h5 ls-1 lh-22">
                      What is Value DEX?
                    </span>
                  </h2>
                  <p className="lh-24 font-sm text-gray-900 rajdhani-50">
                    <span className="lh-24 font-sm text-white rajdhani-500 mt-1 ">
                      Discover the fundamental concepts of the Value DEX: Swaps,
                      Pools, Concentrated Liquidity, and beyond.
                    </span>
                  </p>
                </a>
              </div>
            </div>
            <div className="col-lg-4 ps-1 mt-2">
              <div
                className="rounded-15 p-3 hero-bg-new border dark-border d-flex align-items-center"
                style={{ height: "234px" }}
              >
                <a href="#">
                  <img
                    src="assets/newimg/contracts.webp"
                    className="img-fluid h-45"
                  />
                  <h2 className="post-title lh-16 mt-3 mb-1 d-flex justify-content-between">
                    <span className="rajdhani-600 text-gray-900 text-white h5 ls-1 lh-22">
                      Smart Contracts & Programs
                    </span>
                  </h2>
                  <p className="lh-24 font-sm text-gray-900 rajdhani-50">
                    <span className="lh-24 font-sm text-white rajdhani-500 mt-1 ">
                      Explore the architecture of the Value DEX smart contracts
                      and programs, consisting of the Core and Periphery
                      libraries.
                    </span>
                  </p>
                </a>
              </div>
            </div>
            <div className="col-lg-4 ps-1 mt-2 ">
              <div
                className="rounded-15 p-3 hero-bg-new border dark-border d-flex align-items-center"
                style={{ height: "234px" }}
              >
                <a href="#">
                  <img
                    src="assets/newimg/contracts.webp"
                    className="img-fluid h-45"
                  />
                  <h2 className="post-title lh-16 mt-3 mb-1 d-flex justify-content-between">
                    <span className="rajdhani-600 text-gray-900 text-white h5 ls-1 lh-22">
                      Smart Contracts & Programs
                    </span>
                  </h2>
                  <p className="lh-24 font-sm text-gray-900 rajdhani-50">
                    <span className="lh-24 font-sm text-white rajdhani-500 mt-1 ">
                      Explore the architecture of the Value DEX smart contracts
                      and programs, consisting of the Core and Periphery
                      libraries.
                    </span>
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
