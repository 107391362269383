import React from 'react'
import Header from '../../Components/Coman/Header'
import PrivacyPolicyHero from './PrivacyPolicyHero'
import Footer2 from '../../Components/Coman/Footer2'

export default function PrivacyPolicy() {
  return (
    <div>
              <div className="main-wrapper bg-dark-black">
                <Header/>
                <PrivacyPolicyHero/>
                <Footer2/>
              </div>

    </div>
  )
}
