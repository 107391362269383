import React from "react";
import { Link } from "react-router-dom";

export default function () {
  const today = new Date();
  const year = today.getFullYear();
  return (
    <div>
      <div className=" pt-5 bg-dark2">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-md-4 mt-2  ">
              <p className="text-lg-start text-center rajdhani-600 text-gray-400 font-sm mb-0 mt-2">
                © {year} Value
              </p>
            </div>
            <div className="col-12 col-md-4 mt-2">
              <div className="d-flex align-items-center gap-3  justify-content-center ">
                <a href="https://t.me/valu_so" target="_blank">
                  {" "}
                  <i class="bi bi-telegram text-white fs-2"></i>
                </a>
                <a href="https://github.com/valuedex" target="_blank">
                  {" "}
                  <i class="bi bi-github text-white fs-2"></i>
                </a>
                <a href="https://x.com/valu_so" target="_blank">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    fill="currentColor"
                    class="bi bi-twitter-x text-white fs-2 "
                    viewBox="0 0 16 16"
                  >
                    <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
                  </svg>
                </a>
              </div>
            </div>
            <div className="col-12 col-md-4 mt-2">
              <ul className="list-group flex-row justify-content-lg-end  justify-content-center   gap-md-5 gap-3">
                <li className="list-group-item p-0 bg-transparent border-0">
                  <Link
                    to="/privacy-policy"
                    className="rajdhani-600 text-gray-400 font-sm"
                  >
                    Privacy Policy
                  </Link>
                </li>
                <li className="list-group-item p-0 bg-transparent border-0">
                  <Link to="/terms-conditions" className="rajdhani-600 text-gray-400 font-sm">
                    Terms & Conditions
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="row pt-3 pb-md-5 pb-3">
            <div className="col-md-6"></div>
            {/* <div className="col-md-6 md-mt-2">
              <p className="text-md-end text-center rajdhani-600 text-gray-400 font-sm mb-0">
                © 2022 uitheme copyright.
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
