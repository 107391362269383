import React from "react";

export default function GovernanceHero() {
  return (
    <div>
      <div>
        <div className="featurerapper ">
          <div className="container-wide  mt-lg-100 mt-0 pt--lg-100 pb-4 ">
            <div className="row align-items-center ">
              <div className="12 md-mt-4">
                <h2 className="text-gray-900 dark-text lh-3 mb-2 rajdhani-500 h4 pe-lg-5 ">
                Value Governance

                </h2>
                <h6 className=" dark-text rajdhani-600 h2 ">
                Value is a community-owned decentralized protocol & <br /> it is governed by Value token holders.
                </h6>
              </div>
            </div>
            <div className="row justify-content-center mt-5">
              <div className="col-9">
                <div className="row">
                  <div className="col-md-5">
                    <img src="/logo_01_1-1.webp" height="230" alt="" />
                  </div>
                  <div className="col-md-7">
                    <h6 className=" dark-text rajdhani-600 h2 ">
                    Value Token
                    </h6>
                    <h2 className="text-gray-900 dark-text  rajdhani-500 h6 mt-4">
                    Value is the native token to Value Ecosystem which is rewarded to users. Its primary functionality is providing governing rights to holders.
                    </h2>
                    <a
                      href="#"
                      id=""
                      className="btn btn-md header-btn text-gray-800 rajdhani-500 rounded-6 bg-current px-4 font-sm mt-2  text-white"
                    >
                      Read More <i class="bi bi-arrow-right-short me-2"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
