/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

export default function DApp() {
  return (
    <div>
      <div className="feature-wrapper ">
        <div className="container-wide  py-4 ">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-12">
              <img
                src="assets/newimg/swap_assets_without_bridging.png"
                alt="banner"
                className="img-fluid"
              />
            </div>
            <div className="col-lg-6 col-md-12 ms-auto md-mt-4">
              <h2 className="text-gray-900 dark-text lh-3 mb-4 rajdhani-600 h1 pe-lg-5">
                Swap Assets Without Bridging
              </h2>
              <h6 className="text-gray-700 dark-text rajdhani-500 lh-30 pe-lg-5 me-lg-5">
                As a swap aggregator, Value offers a more efficient way to swap
                crypto assets than decentralized exchanges. It supports multiple
                blockchain networks even including non-EVM chains to achieve
                interoperability.
              </h6>
              {/* <a
                href="#"
                className="btn btn-lg text-gray-900 rajdhani-700 rounded-6 bg-current border-current border-2 px-lg-5 font-sm text-uppercase mt-4"
              >
                Join as creater
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
