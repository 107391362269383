import React from "react";

export default function EcosystemSearch() {
  return (
    <div>
      <div className="feature-wrapper ">
        <div className="container-wide  py-5  ">
          <div className="row  align-items-center">
            <div className="col-xl-8 col-lg-7 col-md-7 col-sm-7 col-12 ">
              <h2 className="text-gray-900 dark-text lh-3  rajdhani-600 h6 mb-0">
                Explore and search for projects built on the Value DEX.
              </h2>
            </div>
            <div className="col-xl-4 col-lg-5 col-md-5 col-sm-5 col-12 mb-0">
              <div className="form-group has-search1 d-flex align-items-center  mb-0">
                <span className="fa fa-search form-control-feedback1  " />
                <input
                  type="text"
                  className="form-control rounded-10 dark-text font-sm"
                  placeholder="Search "
                  style={{ background: "none" }}
                />
              </div>
            </div>
          </div>
          <div className="feature-wrapper  style2 dark-bg3">
            <div className="row mt-2 ">
              <div className="col-xl-3 col-lg-4  col-sm-12 px-1 my-2 ">
                <div className="feature-div rounded-15 eco-he">
                  <h4 className=" d-flex justify-content-between">
                    <img
                      src="assets/newimg/b_.jpg"
                      className="img-fluid h-90"
                    />
                    <p className="font-xs text-white mb-0 ">
                      <span className="bg-black rounded-15 p-2">Dapp</span>
                    </p>
                  </h4>
                  <h2 className="rajdhani-600 text-white h5 mt-2 mb-2">
                    {" "}
                    Saving DAO
                  </h2>
                  <p className="pe-3 op-8 text-white rajdhani-500 font-sm mb-0">
                    Connect, Collab, Create: Bridge to <br /> Web 3
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-12 px-1 my-2 ">
                <div className="feature-div rounded-15 eco-he">
                  <h4 className=" d-flex justify-content-between">
                    <img
                      src="assets/newimg/blast-fantasy.jpg"
                      className="img-fluid h-90"
                    />
                    <p className="font-xs text-white mb-0 ">
                      <span className="bg-black rounded-15 p-2">Dapp</span>
                    </p>
                  </h4>
                  <h2 className="rajdhani-600 text-white h5 mt-2 mb-2">
                    {" "}
                    Blast Fantasy
                  </h2>
                  <p className="pe-3 op-8 text-white rajdhani-500 font-sm mb-0">
                  an innovative GameFi that integrates DeFi elements to maximize user profits, build on
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-12 px-1 my-2 ">
                <div className="feature-div rounded-15 eco-he">
                  <h4 className=" d-flex justify-content-between">
                    <img
                      src="assets/newimg/otopia.jpg"
                      className="img-fluid h-90"
                    />
                    <p className="font-xs text-white mb-0 ">
                      <span className="bg-black rounded-15 p-2">Dapp</span>
                    </p>
                  </h4>
                  <h2 className="rajdhani-600 text-white h5 mt-2 mb-2">
                    {" "}
                    Otopia
                  </h2>
                  <p className="pe-3 op-8 text-white rajdhani-500 font-sm mb-0">
                  The 1st L2 Blockchain for AI. Setting the standard for widespread adoption, fair launches, community ownership in seamless AI-driven blockchain applications.
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-12 px-1 my-2 ">
                <div className="feature-div rounded-15 eco-he">
                  <h4 className=" d-flex justify-content-between">
                    <img
                      src="assets/newimg/zkpep.jpg"
                      className="img-fluid h-90"
                    />
                    <p className="font-xs text-white mb-0 ">
                      <span className="bg-black rounded-15 p-2">Dapp</span>
                    </p>
                  </h4>
                  <h2 className="rajdhani-600 text-white h5 mt-2 mb-2">
                    {" "}
                    zkPEPE
                  </h2>
                  <p className="pe-3 op-8 text-white rajdhani-500 font-sm mb-0">
                  This is $ZKPEPE, more than just a memecoin. 🎮 ZKPEPE Heroes </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-12  px-1 my-2 ">
                <div className="feature-div rounded-15 eco-he">
                  <h4 className=" d-flex justify-content-between">
                    <img
                      src="assets/newimg/atem-network.jpg"
                      className="img-fluid h-90"
                    />
                    <p className="font-xs text-white mb-0 ">
                      <span className="bg-black rounded-15 p-2">Dapp</span>
                    </p>
                  </h4>
                  <h2 className="rajdhani-600 text-white h5 mt-2 mb-2">
                    {" "}
                    Atem Network
                  </h2>
                  <p className="pe-3 op-8 text-white rajdhani-500 font-sm mb-0">
                  A decentralized content creation protocol, we aim at helping creators tokenize their content and build web3 communities </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-12  px-1 my-2 ">
                <div className="feature-div rounded-15 eco-he">
                  <h4 className=" d-flex justify-content-between">
                    <img
                      src="assets/newimg/taskon-1.png"
                      className="img-fluid h-90"
                    />
                    <p className="font-xs text-white mb-0 ">
                      <span className="bg-black rounded-15 p-2">Dapp</span>
                    </p>
                  </h4>
                  <h2 className="rajdhani-600 text-white h5 mt-2 mb-2">
                    {" "}
                    TaskON
                  </h2>
                  <p className="pe-3 op-8 text-white rajdhani-500 font-sm mb-0">
                  A decentralized content creation protocol, we aim at helping creators tokenize their content and build web3 communities </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-12  px-1 my-2 ">
                <div className="feature-div rounded-15 eco-he">
                  <h4 className=" d-flex justify-content-between">
                    <img
                      src="assets/newimg/trend-wallet.jpg"
                      className="img-fluid h-90"
                    />
                    <p className="font-xs text-white mb-0 ">
                      <span className="bg-black rounded-15 p-2">Dapp</span>
                    </p>
                  </h4>
                  <h2 className="rajdhani-600 text-white h5 mt-2 mb-2">
                    {" "}
                    Trend Wallet
                  </h2>
                  <p className="pe-3 op-8 text-white rajdhani-500 font-sm mb-0">
                  TrendWallet is an innovative multi-chain DApp aggregation wallet that provides a secure and efficient platform for users to discover top-tier and trending projects. </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-12  px-1 my-2 ">
                <div className="feature-div rounded-15 eco-he">
                  <h4 className=" d-flex justify-content-between">
                    <img
                      src="assets/newimg/trantordao.jpg"
                      className="img-fluid h-90"
                    />
                    <p className="font-xs text-white mb-0 ">
                      <span className="bg-black rounded-15 p-2">Dapp</span>
                    </p>
                  </h4>
                  <h2 className="rajdhani-600 text-white h5 mt-2 mb-2">
                    {" "}
                    Trantor DAO
                  </h2>
                  <p className="pe-3 op-8 text-white rajdhani-500 font-sm mb-0">
                  A Unified Web3 Portal Promoting Interconnectivity among communities and brands through Empowered Decentralized Identities
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-12  px-1 my-2 ">
                <div className="feature-div rounded-15 eco-he">
                  <h4 className=" d-flex justify-content-between">
                    <img
                      src="assets/newimg/rbi-network.jpg"
                      className="img-fluid h-90"
                    />
                    <p className="font-xs text-white mb-0 ">
                      <span className="bg-black rounded-15 p-2">Layer 2</span>
                    </p>
                  </h4>
                  <h2 className="rajdhani-600 text-white h5 mt-2 mb-2">
                    {" "}
                    REI Network
                  </h2>
                  <p className="pe-3 op-8 text-white rajdhani-500 font-sm mb-0">
                  REI was developed for the evolution trend of the Blockchain, to achieve a lightweight, EVM compatible, higher performance, and no fee blockchain framework
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-12  px-1 my-2 ">
                <div className="feature-div rounded-15 eco-he">
                  <h4 className=" d-flex justify-content-between">
                    <img
                      src="assets/newimg/karmaverse.png"
                      className="img-fluid h-90"
                    />
                    <p className="font-xs text-white mb-0 ">
                      <span className="bg-black rounded-15 p-2">GameFi</span>
                    </p>
                  </h4>
                  <h2 className="rajdhani-600 text-white h5 mt-2 mb-2">
                    {" "}
                    Karmaverse
                  </h2>
                  <p className="pe-3 op-8 text-white rajdhani-500 font-sm mb-0">
                  Karmaverse is a #Metaverse #GameFi project, created by #SLG top game developers.
                  </p>
                </div>
              </div>
              {/* ///////////////////////////////// */}
              <div className="col-xl-3 col-lg-4 col-sm-12  px-1 my-2 ">
                <div className="feature-div rounded-15 eco-he">
                  <h4 className=" d-flex justify-content-between">
                    <img
                      src="assets/newimg/masterpop.jpg"
                      className="img-fluid h-90"
                    />
                    <p className="font-xs text-white mb-0 ">
                      <span className="bg-black rounded-15 p-2">GameFi</span>
                    </p>
                  </h4>
                  <h2 className="rajdhani-600 text-white h5 mt-2 mb-2">
                    {" "}
                    MasterPOP

                  </h2>
                  <p className="pe-3 op-8 text-white rajdhani-500 font-sm mb-0">
                  MasterPop is a Match 3 game promises endless fun and excitement for players. Create a vibrant and inclusive gaming ecosystem powered by blockchain technology so that players can come together to enjoy exciting and rewarding matching experiences                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-12  px-1 my-2 ">
                <div className="feature-div rounded-15 eco-he">
                  <h4 className=" d-flex justify-content-between">
                    <img
                      src="assets/newimg/nowchain.jpg"
                      className="img-fluid h-90"
                    />
                    <p className="font-xs text-white mb-0 ">
                      <span className="bg-black rounded-15 p-2">Dapp</span>
                    </p>
                  </h4>
                  <h2 className="rajdhani-600 text-white h5 mt-2 mb-2">
                  NowChain
                  </h2>
                  <p className="pe-3 op-8 text-white rajdhani-500 font-sm mb-0">
                  NowChain offers unparalleled security and scalability for digital transactions and decentralized applications. utilizing Proof of Mobile (POM) to seamlessly integrate your everyday device.
                  </p>
                </div>
              </div>
              {/* ////////////////////// */}
              <div className="col-xl-3 col-lg-4 col-sm-12  px-1 my-2 ">
                <div className="feature-div rounded-15 eco-he">
                  <h4 className=" d-flex justify-content-between">
                    <img
                      src="assets/newimg/ibit.jpg"
                      className="img-fluid h-90"
                    />
                    <p className="font-xs text-white mb-0 ">
                      <span className="bg-black rounded-15 p-2">Dapp</span>
                    </p>
                  </h4>
                  <h2 className="rajdhani-600 text-white h5 mt-2 mb-2">
                  IBIT
                  </h2>
                  <p className="pe-3 op-8 text-white rajdhani-500 font-sm mb-0">
                  IBIT is an All-in-one Smart Crypto Investment & Trading Platform. </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-12  px-1 my-2 ">
                <div className="feature-div rounded-15 eco-he">
                  <h4 className=" d-flex justify-content-between">
                    <img
                      src="assets/newimg/b18a.jpg"
                      className="img-fluid h-90"
                    />
                    <p className="font-xs text-white mb-0 ">
                      <span className="bg-black rounded-15 p-2">Dapp</span>
                    </p>
                  </h4>
                  <h2 className="rajdhani-600 text-white h5 mt-2 mb-2">
                  b18a
                  </h2>
                  <p className="pe-3 op-8 text-white rajdhani-500 font-sm mb-0">
                  b18a Protocol is a pioneers AI-driven collaboration for blockchain metadata, making data universally accessible and transparent.
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-12  px-1 my-2 ">
                <div className="feature-div rounded-15 eco-he">
                  <h4 className=" d-flex justify-content-between">
                    <img
                      src="assets/newimg/hela-labs.jpg"
                      className="img-fluid h-90"
                    />
                    <p className="font-xs text-white mb-0 ">
                      <span className="bg-black rounded-15 p-2">Layer1</span>
                    </p>
                  </h4>
                  <h2 className="rajdhani-600 text-white h5 mt-2 mb-2">
                  Hela Labs
                  </h2>
                  <p className="pe-3 op-8 text-white rajdhani-500 font-sm mb-0">
                  HeLa is a layer 1 blockchain, powered by a fiat-backed stablecoin. Our architecture reflects our commitment to real-world adoption, featuring modularity, EVM compatibility, stable gas fees, and a decentralized digital identity framework.                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-12  px-1 my-2 ">
                <div className="feature-div rounded-15 eco-he">
                  <h4 className=" d-flex justify-content-between">
                    <img
                      src="assets/newimg/soon-verse.jpg"
                      className="img-fluid h-90"
                    />
                    <p className="font-xs text-white mb-0 ">
                      <span className="bg-black rounded-15 p-2">GameFI
                      </span>
                    </p>
                  </h4>
                  <h2 className="rajdhani-600 text-white h5 mt-2 mb-2">
                  SoonVerse

                  </h2>
                  <p className="pe-3 op-8 text-white rajdhani-500 font-sm mb-0">
                  SoonVerse is an integrated web3 games & metaverse accelerator and incubator for builders and projects, sharing benefits with community members.     </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-12  px-1 my-2 ">
                <div className="feature-div rounded-15 eco-he">
                  <h4 className=" d-flex justify-content-between">
                    <img
                      src="assets/newimg/safe-road-club.jpg"
                      className="img-fluid h-90"
                    />
                    <p className="font-xs text-white mb-0 ">
                      <span className="bg-black rounded-15 p-2">GameFI
                      </span>
                    </p>
                  </h4>
                  <h2 className="rajdhani-600 text-white h5 mt-2 mb-2">
                  Safe Road Club
                  </h2>
                  <p className="pe-3 op-8 text-white rajdhani-500 font-sm mb-0">
                  SRC.ai is a vision data platform to develop LLM for self-driving and vision model using road images collected from SRC dapp users. </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-12  px-1 my-2 ">
                <div className="feature-div rounded-15 eco-he">
                  <h4 className=" d-flex justify-content-between">
                    <img
                      src="assets/newimg/koi.jpg"
                      className="img-fluid h-90"
                    />
                    <p className="font-xs text-white mb-0 ">
                      <span className="bg-black rounded-15 p-2">DeFi
                      </span>
                    </p>
                  </h4>
                  <h2 className="rajdhani-600 text-white h5 mt-2 mb-2">
                  KOI
                  </h2>
                  <p className="pe-3 op-8 text-white rajdhani-500 font-sm mb-0">
                  KOI: Kingdom of Inscription | Dive into an AI-powered game economy fueled by Bitcoin liquidity
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-12  px-1 my-2 ">
                <div className="feature-div rounded-15 eco-he">
                  <h4 className=" d-flex justify-content-between">
                    <img
                      src="assets/newimg/petoshi.jpg"
                      className="img-fluid h-90"
                    />
                    <p className="font-xs text-white mb-0 ">
                      <span className="bg-black rounded-15 p-2">GameFi
                      </span>
                    </p>
                  </h4>
                  <h2 className="rajdhani-600 text-white h5 mt-2 mb-2">
                  Petoshi
                  </h2>
                  <p className="pe-3 op-8 text-white rajdhani-500 font-sm mb-0">
                  Feed,Connect,Earn on @Blast_L2

                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-12  px-1 my-2 ">
                <div className="feature-div rounded-15 eco-he">
                  <h4 className=" d-flex justify-content-between">
                    <img
                      src="assets/newimg/mememaker.png"
                      className="img-fluid h-90"
                    />
                    <p className="font-xs text-white mb-0 ">
                      <span className="bg-black rounded-15 p-2">SocialFi
                      </span>
                    </p>
                  </h4>
                  <h2 className="rajdhani-600 text-white h5 mt-2 mb-2">
                  MemeMaker
                  </h2>
                  <p className="pe-3 op-8 text-white rajdhani-500 font-sm mb-0">
                  Social Network Built for Memers by Memers. Building on BASE chain
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-12  px-1 my-2 ">
                <div className="feature-div rounded-15 eco-he">
                  <h4 className=" d-flex justify-content-between">
                    <img
                      src="assets/newimg/cyber-aI.jpg"
                      className="img-fluid h-90"
                    />
                    <p className="font-xs text-white mb-0 ">
                      <span className="bg-black rounded-15 p-2">AI
                      </span>
                    </p>
                  </h4>
                  <h2 className="rajdhani-600 text-white h5 mt-2 mb-2">
                  Cyber AI
                  </h2>
                  <p className="pe-3 op-8 text-white rajdhani-500 font-sm mb-0">
                  Embark on a journey into Cyber, the epitome of AI innovation, shaping the landscape of tomorrow's technology.
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-12  px-1 my-2 ">
                <div className="feature-div rounded-15 eco-he">
                  <h4 className=" d-flex justify-content-between">
                    <img
                      src="assets/newimg/intoverse.jpg"
                      className="img-fluid h-90"
                    />
                    <p className="font-xs text-white mb-0 ">
                      <span className="bg-black rounded-15 p-2">SocialFi
                      </span>
                    </p>
                  </h4>
                  <h2 className="rajdhani-600 text-white h5 mt-2 mb-2">
                  INTOverse
                  </h2>
                  <p className="pe-3 op-8 text-white rajdhani-500 font-sm mb-0">
                  INTO, a Web3 social protocol based on blockchain and artificial intelligence, integrates functions such as encrypted wallet, SocialFi.
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-12  px-1 my-2 ">
                <div className="feature-div rounded-15 eco-he">
                  <h4 className=" d-flex justify-content-between">
                    <img
                      src="assets/newimg/brainwave-al.jpg"
                      className="img-fluid h-90"
                    />
                    <p className="font-xs text-white mb-0 ">
                      <span className="bg-black rounded-15 p-2">AI
                      </span>
                    </p>
                  </h4>
                  <h2 className="rajdhani-600 text-white h5 mt-2 mb-2">
                  Brainwave AI

                  </h2>
                  <p className="pe-3 op-8 text-white rajdhani-500 font-sm mb-0">
                  Brainwave is a platform that uses artificial intelligence for smart investment decisions. It combines decades of expertise in machine learning and investment management.                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-12  px-1 my-2 ">
                <div className="feature-div rounded-15 eco-he">
                  <h4 className=" d-flex justify-content-between">
                    <img
                      src="assets/newimg/infiblue1.jpg"
                      className="img-fluid h-90"
                    />
                    <p className="font-xs text-white mb-0 ">
                      <span className="bg-black rounded-15 p-2">AI
                      </span>
                    </p>
                  </h4>
                  <h2 className="rajdhani-600 text-white h5 mt-2 mb-2">
                  Infiblue
                  </h2>
                  <p className="pe-3 op-8 text-white rajdhani-500 font-sm mb-0">
                  Infiblue Chat is a blockchain-based social platform within the Infiblue World metaverse, with the aim of redefining our social experiences.   </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-12  px-1 my-2 ">
                <div className="feature-div rounded-15 eco-he">
                  <h4 className=" d-flex justify-content-between">
                    <img
                      src="assets/newimg/uniport.png"
                      className="img-fluid h-90"
                    />
                    <p className="font-xs text-white mb-0 ">
                      <span className="bg-black rounded-15 p-2">DeFi
                      </span>
                    </p>
                  </h4>
                  <h2 className="rajdhani-600 text-white h5 mt-2 mb-2">
                  Uniport
                  </h2>
                  <p className="pe-3 op-8 text-white rajdhani-500 font-sm mb-0">
                  Uniport is a BTC Restaking Layer featured dual-chain architecture, a creative modular blockchain constructed based on Cosmos, Polygon CDK, and Babylon. Together, we're shaping the future of these industries.</p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-12  px-1 my-2 ">
                <div className="feature-div rounded-15 eco-he">
                  <h4 className=" d-flex justify-content-between">
                    <img
                      src="assets/newimg/xei-al.jpg"
                      className="img-fluid h-90"
                    />
                    <p className="font-xs text-white mb-0 ">
                      <span className="bg-black rounded-15 p-2">DeFi
                      </span>
                    </p>
                  </h4>
                  <h2 className="rajdhani-600 text-white h5 mt-2 mb-2">
                  XEI AI

                  </h2>
                  <p className="pe-3 op-8 text-white rajdhani-500 font-sm mb-0">
                  The Single-Source Ecosystem for Enterprise-Level Al Solutions, #CAlaaS Innovation Powered by Decentralized Computing for ML Engineers & DePIN Phase 2</p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-12  px-1 my-2 ">
                <div className="feature-div rounded-15 eco-he">
                  <h4 className=" d-flex justify-content-between">
                    <img
                      src="assets/newimg/gather.jpg"
                      className="img-fluid h-90"
                    />
                    <p className="font-xs text-white mb-0 ">
                      <span className="bg-black rounded-15 p-2">DeFi
                      </span>
                    </p>
                  </h4>
                  <h2 className="rajdhani-600 text-white h5 mt-2 mb-2">
                  Gather
                  </h2>
                  <p className="pe-3 op-8 text-white rajdhani-500 font-sm mb-0">
                  Gather, born for top-secret chats ! Gather = Blockchain + App + Hardware GAT CHAT WEB3 Focus on other account:</p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-12  px-1 my-2 ">
                <div className="feature-div rounded-15 eco-he">
                  <h4 className=" d-flex justify-content-between">
                    <img
                      src="assets/newimg/open-gate-labs.jpg"
                      className="img-fluid h-90"
                    />
                    <p className="font-xs text-white mb-0 ">
                      <span className="bg-black rounded-15 p-2">DeFi
                      </span>
                    </p>
                  </h4>
                  <h2 className="rajdhani-600 text-white h5 mt-2 mb-2">
                  Open Gate Labs
                  </h2>
                  <p className="pe-3 op-8 text-white rajdhani-500 font-sm mb-0">
                  Unlocking the Gateway to Web3: Your Portal to Decentralized Possibilities and Boundless Connectivity. Initiator of Filecoin domains project</p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-12  px-1 my-2 ">
                <div className="feature-div rounded-15 eco-he">
                  <h4 className=" d-flex justify-content-between">
                    <img
                      src="assets/newimg/cakon.jpg"
                      className="img-fluid h-90"
                    />
                    <p className="font-xs text-white mb-0 ">
                      <span className="bg-black rounded-15 p-2">GameFi
                      </span>
                    </p>
                  </h4>
                  <h2 className="rajdhani-600 text-white h5 mt-2 mb-2">
                  Cakon
                  </h2>
                  <p className="pe-3 op-8 text-white rajdhani-500 font-sm mb-0">
                  One-stop Web3 game launch platform based on Ton Blockchain</p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-12  px-1 my-2 ">
                <div className="feature-div rounded-15 eco-he">
                  <h4 className=" d-flex justify-content-between">
                    <img
                      src="assets/newimg/cryptopia.jpg"
                      className="img-fluid h-90"
                    />
                    <p className="font-xs text-white mb-0 ">
                      <span className="bg-black rounded-15 p-2">GameFi
                      </span>
                    </p>
                  </h4>
                  <h2 className="rajdhani-600 text-white h5 mt-2 mb-2">
                  Cryptopia
                  </h2>
                  <p className="pe-3 op-8 text-white rajdhani-500 font-sm mb-0">
                  Explore the frontier of gaming with our next-generation multi-chain platform.</p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-12  px-1 my-2 ">
                <div className="feature-div rounded-15 eco-he">
                  <h4 className=" d-flex justify-content-between">
                    <img
                      src="assets/newimg/hashking.jpg"
                      className="img-fluid h-90"
                    />
                    <p className="font-xs text-white mb-0 ">
                      <span className="bg-black rounded-15 p-2">DeFi
                      </span>
                    </p>
                  </h4>
                  <h2 className="rajdhani-600 text-white h5 mt-2 mb-2">
                  Hashking
                  </h2>
                  <p className="pe-3 op-8 text-white rajdhani-500 font-sm mb-0">
                  A decentralized digital asset staking protocol that offers transparent, trusted and secure staking services.
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-12  px-1 my-2 ">
                <div className="feature-div rounded-15 eco-he">
                  <h4 className=" d-flex justify-content-between">
                    <img
                      src="assets/newimg/kingdomx.png"
                      className="img-fluid h-90"
                    />
                    <p className="font-xs text-white mb-0 ">
                      <span className="bg-black rounded-15 p-2">GameFi
                      </span>
                    </p>
                  </h4>
                  <h2 className="rajdhani-600 text-white h5 mt-2 mb-2">
                  KingdomX
                  </h2>
                  <p className="pe-3 op-8 text-white rajdhani-500 font-sm mb-0">
                  kingdomx.co is a Metaverse Medieval Game Designed Around Gamefi Protocol
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-12  px-1 my-2 ">
                <div className="feature-div rounded-15 eco-he">
                  <h4 className=" d-flex justify-content-between">
                    <img
                      src="assets/newimg/metaarena1.jpg"
                      className="img-fluid h-90"
                    />
                    <p className="font-xs text-white mb-0 ">
                      <span className="bg-black rounded-15 p-2">GameFi
                      </span>
                    </p>
                  </h4>
                  <h2 className="rajdhani-600 text-white h5 mt-2 mb-2">
                  MetaArena
                  </h2>
                  <p className="pe-3 op-8 text-white rajdhani-500 font-sm mb-0">
                  MetaArena - Building a Next-Generation #Web3 Gaming Ecosystem
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-12  px-1 my-2 ">
                <div className="feature-div rounded-15 eco-he">
                  <h4 className=" d-flex justify-content-between">
                    <img
                      src="assets/newimg/din.jpg"
                      className="img-fluid h-90"
                    />
                    <p className="font-xs text-white mb-0 ">
                      <span className="bg-black rounded-15 p-2">AI
                      </span>
                    </p>
                  </h4>
                  <h2 className="rajdhani-600 text-white h5 mt-2 mb-2">
                  DIN
                  </h2>
                  <p className="pe-3 op-8 text-white rajdhani-500 font-sm mb-0">
                  The First Modular Al-Native Data Pre-Processing Layer and Collect Al Data Get Paid
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-12  px-1 my-2 ">
                <div className="feature-div rounded-15 eco-he">
                  <h4 className=" d-flex justify-content-between">
                    <img
                      src="assets/newimg/layerx-protocol.jpg"
                      className="img-fluid h-90"
                    />
                    <p className="font-xs text-white mb-0 ">
                      <span className="bg-black rounded-15 p-2">DeFi
                      </span>
                    </p>
                  </h4>
                  <h2 className="rajdhani-600 text-white h5 mt-2 mb-2">
                  LayerX Protocol
                  </h2>
                  <p className="pe-3 op-8 text-white rajdhani-500 font-sm mb-0">
                  Discover the Undiscovered: Unravel the Enigma in a Boundless Blockchain Adventure.
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-12  px-1 my-2 ">
                <div className="feature-div rounded-15 eco-he">
                  <h4 className=" d-flex justify-content-between">
                    <img
                      src="assets/newimg/biton.jpg"
                      className="img-fluid h-90"
                    />
                    <p className="font-xs text-white mb-0 ">
                      <span className="bg-black rounded-15 p-2">DeFi
                      </span>
                    </p>
                  </h4>
                  <h2 className="rajdhani-600 text-white h5 mt-2 mb-2">
                  Biton
                  </h2>
                  <p className="pe-3 op-8 text-white rajdhani-500 font-sm mb-0">
                  A Full-Chain Ecology Platform, offers one-stop multi-chain compatible services to simplify the trading process and mitigate cross-chain risks.
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-12  px-1 my-2 ">
                <div className="feature-div rounded-15 eco-he">
                  <h4 className=" d-flex justify-content-between">
                    <img
                      src="assets/newimg/metaarena.jpg"
                      className="img-fluid h-90"
                    />
                    <p className="font-xs text-white mb-0 ">
                      <span className="bg-black rounded-15 p-2">Layer 1
                      </span>
                    </p>
                  </h4>
                  <h2 className="rajdhani-600 text-white h5 mt-2 mb-2">
                  Orange Web3
                  </h2>
                  <p className="pe-3 op-8 text-white rajdhani-500 font-sm mb-0">
                  Orange is a Layer 1 blockchain focused on User Generated Content (UGC) tools for Web3.
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-12  px-1 my-2 ">
                <div className="feature-div rounded-15 eco-he">
                  <h4 className=" d-flex justify-content-between">
                    <img
                      src="assets/newimg/bru-finance.jpg"
                      className="img-fluid h-90"
                    />
                    <p className="font-xs text-white mb-0 ">
                      <span className="bg-black rounded-15 p-2">DeFi
                      </span>
                    </p>
                  </h4>
                  <h2 className="rajdhani-600 text-white h5 mt-2 mb-2">
                  Orange Web3
                  </h2>
                  <p className="pe-3 op-8 text-white rajdhani-500 font-sm mb-0">
                  Orange is a Layer 1 blockchain focused on User Generated Content (UGC) tools for Web3.
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-12  px-1 my-2 ">
                <div className="feature-div rounded-15 eco-he">
                  <h4 className=" d-flex justify-content-between">
                    <img
                      src="assets/newimg/star-protocol.jpg"
                      className="img-fluid h-90"
                    />
                    <p className="font-xs text-white mb-0 ">
                      <span className="bg-black rounded-15 p-2">DeFi
                      </span>
                    </p>
                  </h4>
                  <h2 className="rajdhani-600 text-white h5 mt-2 mb-2">
                  star-protocol
                  </h2>
                  <p className="pe-3 op-8 text-white rajdhani-500 font-sm mb-0">
                  Building omni-chain identity protocol, powered by LayerZero
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-12  px-1 my-2 ">
                <div className="feature-div rounded-15 eco-he">
                  <h4 className=" d-flex justify-content-between">
                    <img
                      src="assets/newimg/polymer.jpg"
                      className="img-fluid h-90"
                    />
                    <p className="font-xs text-white mb-0 ">
                      <span className="bg-black rounded-15 p-2">DeFi
                      </span>
                    </p>
                  </h4>
                  <h2 className="rajdhani-600 text-white h5 mt-2 mb-2">
                  Polymer
                  </h2>
                  <p className="pe-3 op-8 text-white rajdhani-500 font-sm mb-0">
                  Connect effortlessly links to multiple blockchains, unlocking a plethora of features and boundless potential.
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-12  px-1 my-2 ">
                <div className="feature-div rounded-15 eco-he">
                  <h4 className=" d-flex justify-content-between">
                    <img
                      src="assets/newimg/mimt.jpg"
                      className="img-fluid h-90"
                    />
                    <p className="font-xs text-white mb-0 ">
                      <span className="bg-black rounded-15 p-2">DeFi
                      </span>
                    </p>
                  </h4>
                  <h2 className="rajdhani-600 text-white h5 mt-2 mb-2">
                MTMT
                  </h2>
                  <p className="pe-3 op-8 text-white rajdhani-500 font-sm mb-0">
                  MTMT SFT Liquidity Application Protocol - allows more users to use the MTMT BTCfi platform for application and development
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-12  px-1 my-2 ">
                <div className="feature-div rounded-15 eco-he">
                  <h4 className=" d-flex justify-content-between">
                    <img
                      src="assets/newimg/runelink.png"
                      className="img-fluid h-90"
                    />
                    <p className="font-xs text-white mb-0 ">
                      <span className="bg-black rounded-15 p-2">DeFi
                      </span>
                    </p>
                  </h4>
                  <h2 className="rajdhani-600 text-white h5 mt-2 mb-2">
                  Runelink
                  </h2>
                  <p className="pe-3 op-8 text-white rajdhani-500 font-sm mb-0">
                  The first Al generated, highly composable, editable 3D metaverse protocol on the # Bitcoin blockchain based on Rune.
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-12  px-1 my-2 ">
                <div className="feature-div rounded-15 eco-he">
                  <h4 className=" d-flex justify-content-between">
                    <img
                      src="assets/newimg/go-action.jpg"
                      className="img-fluid h-90"
                    />
                    <p className="font-xs text-white mb-0 ">
                      <span className="bg-black rounded-15 p-2">DeFi
                      </span>
                    </p>
                  </h4>
                  <h2 className="rajdhani-600 text-white h5 mt-2 mb-2">
                  Go Action
                  </h2>
                  <p className="pe-3 op-8 text-white rajdhani-500 font-sm mb-0">
                  The first RWA-integrated multi-move app powered by NFT AMM,
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-12  px-1 my-2 ">
                <div className="feature-div rounded-15 eco-he">
                  <h4 className=" d-flex justify-content-between">
                    <img
                      src="assets/newimg/domus-ai.png"
                      className="img-fluid h-90"
                    />
                    <p className="font-xs text-white mb-0 ">
                      <span className="bg-black rounded-15 p-2">DeFi
                      </span>
                    </p>
                  </h4>
                  <h2 className="rajdhani-600 text-white h5 mt-2 mb-2">
                  Domus AI
                  </h2>
                  <p className="pe-3 op-8 text-white rajdhani-500 font-sm mb-0">
                  Global leader in smart homes. Integrated systems with WEB3 and Al for high-quality living.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-11 px-1">
                <h2 className="text-gray-900 dark-text  rajdhani-500 h6 mb-0 mt-3 text-md-start text-sm-center text-center">
                  Details about applications, services, and websites not
                  developed, controlled, or tested by Value DEX are provided
                  without recommendation or endorsement. Value DEX holds no
                  responsibility for the selection, performance, security,
                  accuracy, or use of third-party offerings. For more
                  information on each project, please reach out to the
                  developer.
                </h2>
            
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
