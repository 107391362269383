import React from "react";

export default function GovernanceApps() {
  return (
    <div>
      <div className="feature-wrapper mt-3">
        <div className="container-wide  py-5  ">
          <div className="row">
            <div className="12 md-mt-4">
              <h2 className="text-gray-900 dark-text lh-3 mb-2 rajdhani-500 h4  pe-lg-5 ">
                Join Governance
              </h2>
              <h6 className=" dark-text rajdhani-600 h3 ">
                Acquire Value & join our governing members in <br /> shaping the
                future for Value ecosystem.
              </h6>
            </div>

            <div className="col-lg-6 ps-1 mt-2">
              <div
                className="rounded-15 p-3 hero-bg-new border dark-border"
                style={{ height: "188px" }}
              >
                <a>
                  <img
                    src="assets/newimg/vote_ on_proposals.png"
                    class="img-fluid h-45"
                  />
                  <a
                    href="#"
                    className="post-title lh-16 my-2 mt-3 d-flex justify-content-between"
                  >
                    <span className="rajdhani-600 text-gray-900 text-white h5 ">
                      Vote on Proposals{" "}
                      <i class="bi bi-arrow-up-right h5 text-white ms-2"></i>
                    </span>
                  </a>
                  <p className="lh-24 font-sm text-gray-900 rajdhani-50">
                    <span className="lh-24 font-sm text-white rajdhani-500 mt-1 ">
                      Cast your votes on existing governing proposals.
                    </span>
                  </p>
                </a>
              </div>
            </div>
            <div className="col-lg-6 ps-1 mt-2">
              <div
                className="rounded-15 p-3 hero-bg-new border dark-border"
                style={{ height: "188px" }}
              >
                <a>
                  <img
                    src="assets/newimg/create_a_proposal .png"
                    class="img-fluid h-45"
                  />
                  <a
                    href="#"
                    className="post-title lh-16 my-2 mt-3 d-flex justify-content-between"
                  >
                    <span className="rajdhani-600 text-gray-900 text-white h5 ">
                      Create a Proposal{" "}
                      <i class="bi bi-arrow-up-right h5 text-white ms-2"></i>
                    </span>
                  </a>
                  <p className="lh-24 font-sm text-gray-900 rajdhani-50">
                    <span className="lh-24 font-sm text-white rajdhani-500 mt-1 ">
                      Introduce new development or propose a change to the Value
                      ecosystem.
                    </span>
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
