import React from 'react'
import Header from '../../Components/Coman/Header'
import CommunityHero from './CommunityHero'
import CommunityJoin from './CommunityJoin'
import CommunitBrand from './CommunitBrand'
import Footer2 from '../../Components/Coman/Footer2'

export default function Community() {
  return (
    <div>
          <div className="main-wrapper bg-dark-black">
            <Header/>
            <CommunityHero/>
            <CommunityJoin/>
            <CommunitBrand/>
            <Footer2/>
          </div>
    </div>
  )
}
