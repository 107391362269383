/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

export default function CommunityHero() {
  return (
    <div>
      <div className="container-wide  py-5  ">
        <div className="row align-items-center">
          <div className="col-md-6">
            <div
              className=" rounded-10 p-md-5 p-4 "
              style={{
                backgroundImage: "url(assets/images/base.png)",
                height: "328px",
                backgroundSize: "cover",
              }}
            >
              <article className="post-article w-100 d-flex flex-column mt-3">
                <h2 className="post-title lh-26 mt-2">
                  <a className="rajdhani-600 text-gray-900  h4 lh-28">
                    The first community-driven DEX aggregator
                  </a>
                </h2>
                <p className="lh-24 font-sm text-gray-900 rajdhani-500 mt-3">
                  Value is managed by a community of core developers while
                  keeping its access completely decentralized. .
                </p>
              </article>
            </div>
          </div>
          <div className="col-md-6">
            <div className=" ms-sm-0 ms-md-4 ms-0 ">
              <div className="row mt-sm-2 mt-2 mt-md-0">
                <div className="col-12 col-md-12 ">
                  <div className="rounded-15 p-3 hero-bg-new border dark-border">
                    <a href="#">
                      <h2 className="post-title lh-16 mt-1 mb-1">
                        <span className="rajdhani-600 text-gray-900 text-white h5 ls-1 lh-22">
                          Value Governance{" "}
                          <i class="bi bi-arrow-up-right h6 text-white"></i>
                        </span>
                      </h2>
                      <p className="lh-24 font-sm text-gray-900 rajdhani-50">
                        <span className="lh-24 font-sm text-white rajdhani-500 mt-1 ">
                          Vote for new integrations & changes on Value.
                        </span>
                      </p>
                    </a>
                  </div>
                </div>
                <div className="col-12 col-md-12 mt-3 ">
                  <div className="rounded-15 p-3 hero-bg-new border dark-border">
                    <a href="#">
                      <h2 className="post-title lh-16 mt-1 mb-1">
                        <span className="rajdhani-600 text-gray-900 text-white h5 ls-1 lh-22">
                          Developers{" "}
                          <i class="bi bi-arrow-up-right h6 text-white"></i>{" "}
                        </span>
                      </h2>
                      <p className="lh-24 font-sm text-gray-900 rajdhani-500">
                        <span className="lh-24 font-sm text-white rajdhani-500 ">
                          Explore developer documents to access APIs.
                        </span>
                      </p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
