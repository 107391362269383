import React from "react";

export default function Howitworks() {
  return (
    <div>
      <div className="feature-wrapper ">
        <div className="container-wide py-md-5 py-sm-4 py-4 ">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <img
                src="assets/newimg/how_it_works.png"
                alt="banner"
                className="img-fluid h-100 w-100"
              />
            </div>
            <div className="col-lg-6 col-md-12 ms-auto md-mt-4">
              <h2 className="text-gray-900 dark-text lh-3 mb-4 rajdhani-600 h1 pe-lg-5">
                How it works{" "}
              </h2>
              <h6 className="text-gray-700 dark-text-1 rajdhani-500 lh-30 ">
                Value fetches the hundreds of liquidity markets across the whole
                DeFi ecosystem for the swap you want to perform between two
                assets. It automatically generates a route which offers the best
                swapping rate while keeping slippage & transaction cost as low
                as possible.
              </h6>
              <a
                href="#"
                className="btn btn-md header-btn text-gray-800 rajdhani-700 rounded-6 bg-current px-4 font-sm text-white"
              >
                Documentation
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
