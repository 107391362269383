import React from 'react'

export default function TermsConditionsHero() {
  return (
    <div>
          <div className="feature-wrapper ">
        <div className="container-wide  mt-lg-100 mt-0 pt--lg-100 pb-3 ">
          <div className="row align-items-center">
            <div className="12 md-mt-4">
              <h2 className="text-gray-900 dark-text lh-3 mb-4 rajdhani-600 h2 pe-lg-5">
              Terms & Conditions
              </h2>
              <div style={{borderTop:"0.6px solid white"}} />
              <p className="rajdhani-500 dark-text font-sm mt-1  text-gray-700 ">
              Welcome to Value DEX! Prior to utilizing our decentralized exchange platform, we kindly request that you carefully review the following terms and conditions. Your access to or use of Value DEX constitutes your agreement to be bound by these terms and conditions.
</p>
<ul class="a ps-3">
  <li className="rajdhani-500 dark-text font-sm mt-1  text-gray-700 ">
  Use of the Platform: Value DEX serves as a decentralized exchange platform enabling the trading of digital assets. By engaging with our platform, you affirm your commitment to adhering to all pertinent laws and regulations.
</li>
<li className="rajdhani-500 dark-text font-sm mt-1  text-gray-700 ">
User Conduct: In utilizing Value DEX, you pledge to conduct yourself in a responsible and lawful manner. You undertake not to partake in any activities that may prove detrimental, unlawful, or disruptive to the platform or fellow users.
</li>
<li className="rajdhani-500 dark-text font-sm mt-1  text-gray-700 ">
Security: The safeguarding of your account and login credentials is your responsibility. You expressly agree not to disclose your account information to any third party and to promptly notify us in the event of any suspected unauthorized access to your account.
</li>
<li className="rajdhani-500 dark-text font-sm mt-1  text-gray-700 ">
Trading Risks: The trading of digital assets carries inherent risks, including the potential loss of funds. Your utilization of Value DEX signifies your acknowledgment and acceptance of these risks, with a clear understanding that you bear sole responsibility for your trading decisions.
</li>
<li className="rajdhani-500 dark-text font-sm mt-1  text-gray-700 ">
Privacy: We are unwavering in our commitment to preserving the privacy and personal information of our users. By engaging with Value DEX, you are in agreement with our Privacy Policy, delineating the methods by which we collect, utilize, and safeguard your data.
</li>
<li className="rajdhani-500 dark-text font-sm mt-1  text-gray-700 ">
Disclaimer of Warranties: Value DEX is presented “as is” and “as available” without any warranties of any kind. We make no assurances regarding the accuracy, completeness, or reliability of the platform or its content.
</li>
<li className="rajdhani-500 dark-text font-sm mt-1  text-gray-700 ">
Limitation of Liability: Under no circumstances shall Value DEX or its affiliates be held liable for any direct, indirect, incidental, special, or consequential damages arising from or in any way connected with the use of our platform.
</li>
<li className="rajdhani-500 dark-text font-sm mt-1  text-gray-700 ">
Changes to Terms and Conditions: We retain the right to revise or amend these terms and conditions at our discretion. Users will be duly notified of any alterations, and continued use of Value DEX subsequent to such changes denotes acceptance of the updated terms.
</li>
<li className="rajdhani-500 dark-text font-sm mt-1  text-gray-700 ">
Termination: We reserve the right to terminate or suspend your access to Value DEX at our discretion, without prior notice, if we have reason to believe that you have contravened these terms and conditions.
</li>
</ul>
<p className="rajdhani-500 dark-text font-sm mt-4  text-gray-700 ">
Your utilization of Value DEX signifies your acceptance of these terms and conditions. For any inquiries or concerns, please do not hesitate to contact us at support@valu.so.

</p>

              
            </div>
          </div>
       
        </div>
      </div>
    </div>
  )
}
