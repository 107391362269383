import React from "react";

export default function EcosystemHero() {
  return (
    <div>
      <div className="feature-wrapper ">
        <div className="container-wide  mt-lg-100 mt-0 pt--lg-100  ">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 md-mt-4">
            <h2 className="text-gray-900 dark-text lh-3 mb-2 rajdhani-500 h4  pe-lg-5 "> Value Ecosystem</h2>

              <h6 className="text-gray-700 dark-text-1 rajdhani-500 lh-30 ">
              Explore the wide ecosystem of Value consisting of 4 major blockchain networks – Ethereum, Solana, BNB Smart Chain & Polygon!
              </h6>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <img
                src="assets/newimg/eco-hero.jpg"
                className="img-fluid w-100 rounded-15"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
