/* eslint-disable react/jsx-no-target-blank */
import React from "react";

export default function NewHero() {
  return (
    <div>
      <div
        className="banner-wrapper d-flex align-items-center mt-lg-100 mt-0 posr bg-image-cover dark-bg bg-image-none-dark bg-image-center bg-no-repeat"
        style={{ height: "512px" }}
      >
        <video muted autoPlay loop id="myVideo" className="video">
          <source src="assets/video/hero.mp4" type="video/mp4" />
        </video>
        <div className="banner-pattern" style={{ zIndex: 1 }}>
          {/* <img src="images/pattern-2.png" alt="pattern" className="ani4 pattern1 w-51" />
    <img src="images/planet-5.webp" alt="pattern" className="ani2 pattern2 w-110" />
    <img src="images/pattern-1.png" alt="pattern" className="ani4 pattern3" />
    <img src="images/pattern-2.png" alt="pattern" className="ani2 pattern4" />
    <img src="images/planet-6.webp" alt="pattern" className="ani4 pattern5 w-110" />
    <img src="images/pattern-3.png" alt="pattern" className="ani3 pattern6" /> */}
        </div>
        <div className="container-wide py-lg-5">
          <div className="row justify-content-center pt-5 pb-3">
            <div className="col-12 col-sm-10 col-md-9  z-index-5 posr">
              <h2 className="text-gray-900 text-white rajdhani-700 lh-2 display1-size mb-2">
                Multichain Swap Aggregator
              </h2>
              <p className="rajdhani-500 text-white font-sm lh-28 text-gray-900 mt-0">
                Execute your Swaps via our aggregator protocol to find the best
                pricing route with lowest slippage & instant transaction
                finality.
              </p>
              <a
                href="https://app.value.xeer.store"
                target="_blank"
                className="btn btn-md header-btn text-gray-800 rajdhani-700 rounded-6 bg-current px-4 font-sm  text-white"
              >
                {" "}
                Enter dApp
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
