import React from "react";
import Header from "../../Components/Coman/Header";
import DevelopersHero from "./DevelopersHero";
import Footer2 from "../../Components/Coman/Footer2";
import DevelopersMan from "./DevelopersMan";

export default function DevelopersNew() {
  return (
    <div>
      <div className="main-wrapper bg-dark-black">
  <Header/>
  <DevelopersHero/>
  <DevelopersMan/>
  <Footer2/>
      </div>
    </div>
  );
}
