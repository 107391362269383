/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState } from "react";
import { globalStates } from "../../contexts/GlobalStates";
import { Link, useLocation } from "react-router-dom";

export default function Header() {
  const { theme, toggleTheme } = useContext(globalStates);
  const [showColor, setShowColor] = useState(0);
  const epoint = useLocation().pathname;



  const [showNavbar, setShowNavbar] = useState(false);
  const toggleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  return (
    <div>
      <header className="header-wrapper w-100">
        <nav className="navbar navbar-expand-lg navbar-light border-0">
          <div className="container posr">
            <Link className="navbar-brand light-logo" to="/">
              <img src="../logo.png" className="w-150" alt="logo" />
            </Link>
           
            <button
              className={`navbar-toggler ${showNavbar ? "" : "collapsed"}`}
              type="button"
              onClick={toggleShowNavbar}
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div
              className={`collapse navbar-collapse ${showNavbar ? "show" : ""}`}
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav mx-auto mb-2 mb-lg-0" id="navbar-main">
                <li className="nav-item menu-item-has-children menu-item-has-megamenu">
                  <Link to="/ecosystem" className="nav-link "  onClick={() => setShowColor(0)}>
                   <span  className={epoint === "/ecosystem"  ? "nav-active-color" : "dark-text"}> Ecosystem</span>
                  </Link>
                </li>
                <li className="nav-item menu-item-has-children">
                  <Link className="nav-link " to="/community" onClick={() => setShowColor(1)}> 
                  <span  className={epoint === "/community"  ? "nav-active-color" : "dark-text"}> Community</span>
                   
                  </Link>
                </li>
                <li className="nav-item menu-item-has-children">
                  <Link to="/governance" className="nav-link  "  onClick={() => setShowColor(2)}>
                    <span  className={epoint === "/governance"  ? "nav-active-color" : "dark-text"}> Governance</span>

                  </Link>
                </li>
                <li className="nav-item menu-item-has-children">
                  <Link className="nav-link dark-text " to="/developers" onClick={() => setShowColor(3)}>
                  <span  className={epoint === "/developers"  ? "nav-active-color" : "dark-text"}> Developers</span>
                  </Link>
                </li>
                <li className="nav-item menu-item-has-children">
                  <Link className="nav-link dark-text " target="_blank" to="https://valu.so/blog/" onClick={() => setShowColor(4)}>
                  <span  className={epoint === "https://valu.so/blog/"  ? "nav-active-color" : "dark-text"}> Blog</span>
                  </Link>
                </li>
                {/* <li className="nav-item menu-item-has-children">
                  <a className="nav-link text-white " href="#">
                    Blog
                  </a>
                </li> */}
              </ul>
              <a
                href="https://app.value.xeer.store"
                target="_blank"
                className="btn btn-lg menu-btn border-0 text-white rajdhani-600 rounded-6 bg-current  px-4 font-sm "
              >
                Launch dApp
              </a>
              {/* <a className="ms-2 menu-btn btn-toggle-dark btn btn-icon ">
                {theme === "dark-mode" ? (
                  <>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="currentColor"
                      className=" bi bi-sun dark-text dark-icon-done"
                      viewBox="0 0 16 16"
                      onClick={() => toggleTheme(theme === "dark-mode")}
                    >
                      <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z" />
                    </svg>
                  </>
                ) : (
                  <>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="currentColor"
                      className="bi bi-moon-stars-fill dark-text  light-icon-done"
                      viewBox="0 0 16 16"
                      onClick={() => toggleTheme(theme === "light-mode")}
                    >
                      <path d="M6 .278a.77.77 0 0 1 .08.858 7.2 7.2 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277q.792-.001 1.533-.16a.79.79 0 0 1 .81.316.73.73 0 0 1-.031.893A8.35 8.35 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.75.75 0 0 1 6 .278" />
                      <path d="M10.794 3.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387a1.73 1.73 0 0 0-1.097 1.097l-.387 1.162a.217.217 0 0 1-.412 0l-.387-1.162A1.73 1.73 0 0 0 9.31 6.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387a1.73 1.73 0 0 0 1.097-1.097zM13.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.16 1.16 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.16 1.16 0 0 0-.732-.732l-.774-.258a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732z" />
                    </svg>
                  </>
                )}
              </a> */}
            </div>
            <a
              href="https://app.value.xeer.store"
              target="_blank"
              id="connetw"
              className="btn btn-md header-btn text-gray-800 rajdhani-700 rounded-6 bg-current px-4 font-sm  text-white"
            >
              Launch dApp
            </a>
            {/* <a href="#" className="account posr header-btn" id="accountAvater">
              <figure className="avater avater-online mb-0">
                <img
                  src="assets/images/avater-2.png"
                  alt="user"
                  className="w-45"
                />
              </figure>
            </a>
            <span className="ms-2 btn header-btn ">
              {theme === "dark-mode" ? (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="currentColor"
                    className="bi bi-sun dark-text dark-icon-done"
                    viewBox="0 0 16 16"
                    onClick={() => toggleTheme(theme === "dark-mode")}
                  >
                    <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z" />
                  </svg>
                </>
              ) : (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="currentColor"
                    className=" bi bi-moon-stars-fill light-icon-done"
                    viewBox="0 0 16 16"
                    onClick={() => toggleTheme(theme === "light-mode")}
                  >
                    <path d="M6 .278a.77.77 0 0 1 .08.858 7.2 7.2 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277q.792-.001 1.533-.16a.79.79 0 0 1 .81.316.73.73 0 0 1-.031.893A8.35 8.35 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.75.75 0 0 1 6 .278" />
                    <path d="M10.794 3.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387a1.73 1.73 0 0 0-1.097 1.097l-.387 1.162a.217.217 0 0 1-.412 0l-.387-1.162A1.73 1.73 0 0 0 9.31 6.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387a1.73 1.73 0 0 0 1.097-1.097zM13.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.16 1.16 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.16 1.16 0 0 0-.732-.732l-.774-.258a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732z" />
                  </svg>
                </>
              )}
            </span> */}
          </div>
        </nav>
      </header>
    </div>
  );
}
