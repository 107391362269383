import React from "react";

export default function CommunitBrand() {
  return (
    <div>
      <div className="feature-wrapper pb-4">
        <div className="container-wide  pb-5 pt-3  ">
          <div className="row  align-items-center">
            <h2 className="text-gray-900 dark-text lh-3  rajdhani-600 h2 mb-0">
              Brand assets
            </h2>
            <h2 class="text-gray-900 dark-text  rajdhani-500 h6  mt-3">
              Download the logo and other brand assets here.
            </h2>
            <div className=" col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="nft-div border dark-bg3 rounded-15 dark-border p-3 mt-4">
                <div className="d-flex justify-content-between ">
                  <img src="../logo.png" className="img-fluid" alt="logo" />
                  <a
                    href="./assets/VALUE-MEDIA-KIT.zip"
                    download
                    className="btn btn-icon btn-sm rounded-pill ms-auto dark-text dark-border m-0 border"
                  >
                    <img
                      decoding="async"
                      width={35}
                      height={35}
                      src="https://valu.so/wp-content/uploads/2024/04/download.webp"
                      className="attachment-large size-large wp-image-1246"
                      alt=""
                    />

                    {/* <i class="bi l"></i> */}
                  </a>
                </div>
                <div className="d-flex mt-2 p-1">
                  <h2 className="rajdhani-600 text-gray-900 dark-text h5 mb-0">
                    Value DEX Logo
                  </h2>
                </div>
                <h2 className="text-gray-900 dark-text  rajdhani-500 h6  mt-2 ">
                  Frequently utilized when displaying the complete Value DEX
                  branding.
                </h2>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="nft-div border dark-bg3 rounded-15 dark-border p-3 mt-4">
                <div className="d-flex justify-content-between ">
                  <img src="../logo.png" className="img-fluid" alt="logo" />
                  <a
                    href="./assets/VALUE-MEDIA-KIT.zip"
                    download
                    className="btn btn-icon btn-sm rounded-pill ms-auto dark-text dark-border m-0 border"
                  >
                    <img
                      decoding="async"
                      width={35}
                      height={35}
                      src="https://valu.so/wp-content/uploads/2024/04/download.webp"
                      className="attachment-large size-large wp-image-1246"
                      alt=""
                    />

                    {/* <i class="bi l"></i> */}
                  </a>
                </div>
                <div className="d-flex mt-2 p-1">
                  <h2 className="rajdhani-600 text-gray-900 dark-text h5 mb-0">
                    Image Assets
                  </h2>
                </div>
                <h2 className="text-gray-900 dark-text  rajdhani-500 h6  mt-2 ">
                  Banners and backgrounds that can complement other brand
                  assets.
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
