import React from "react";
import EcosystemHero from "./EcosystemHero";
import Header from "../../Components/Coman/Header";
import EcosystemSearch from "./EcosystemSearch";
import Footer2 from "../../Components/Coman/Footer2";

export default function Ecosystem() {
  return (
    <div>
      <div className="main-wrapper bg-dark-black">
        <Header />
        <EcosystemHero />
        <EcosystemSearch/>
        <Footer2/>
       
      </div>
    </div>
  );
}
